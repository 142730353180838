import '../styles/index.scss';
import 'slick-carousel/slick/slick.css';

import $ from 'jquery';
window.$ = $;
import slick from 'slick-carousel';


const sliderConfig = {
  'feedback': {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    customPaging: () => '<button class="custom-pager"></button>'
  }
};


$('.feedback-slider').slick( sliderConfig.feedback );

// ScrollReveal().reveal('.section', { duration: 1500, distance: '0px' });


$('.header__nav a[href^="#"], .btn').on('click', function(event) {
  var target = $(this.getAttribute('href'));
  if( target.length ) {
      event.preventDefault();
      $('html, body').stop().animate({
          scrollTop: target.offset().top
      }, 1000);
  }
});

// AOS.init();

